import {initializeApp} from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDAmWBfrkVA6eezwiHcZWc6EKKZ5kq3Fhc",
    authDomain: "boosterhigh-0.firebaseapp.com",
    projectId: "boosterhigh-0",
    storageBucket: "boosterhigh-0.appspot.com",
    messagingSenderId: "220008459863",
    appId: "1:220008459863:web:b2876373e8fd9ef2567d12"
  };
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app)