import React from 'react'
import DateDisplay from '../components/DateDisplay'
import EventsList from '../components/EventsList'
import AddEvent from '../components/AddEvent'


const HomePage: React.FC = () => {
    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <h1 style={{ fontSize: '4em' }}>Skyline High!</h1>
            <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <DateDisplay />
                <AddEvent />
            </div>
            <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <EventsList />
            </div>

        </div>


    )
}

export default HomePage
