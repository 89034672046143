import React, { useEffect, useState } from 'react';
import { query, orderBy, limit, collection, getDocs } from 'firebase/firestore';
import { db } from '../lib/init-firebase'
import moment from 'moment'


export default function ListEvents() {
    const [events, setEvents] = useState([])
    useEffect(() => {
        getEvents()
    }, [])
    useEffect(() => {
        console.log('events', events)
        
    }, [events])
    function getEvents() {
        const eventsCollectionRef = collection(db, 'events')
        const q = query(eventsCollectionRef, orderBy("date"), limit(10));
        getDocs(q)
            .then(response => {
                console.log(response)
                const evs = response.docs.map(doc => ({
                    data: doc.data(),
                    id: doc.id
                }))
                setEvents(evs)
            })
            .catch(error => console.log(error.message))
    }
    return (
        <>
            <h2>Events</h2>
            <ul>
                {events.map(event => {
                    let date = new Date(event.data.date)
                    let endDate = new Date(event.data.endDate)
                    let dateString = date.toDateString()
                    let timeString = date.toLocaleTimeString()
                    let modTimeString = timeString.substring(0, timeString.length-6);
                    let endDateString = endDate.toDateString()
                    let endTimeString = endDate.toLocaleTimeString()
                    let modEndTimeString = endTimeString.substring(0, endTimeString.length-6);
                    return (
                        <li key={event.id}>
                            <h3 >{`${event.data.eventTitle} - ${event.data.clubName}`}</h3>
                            <p>{`Date: ${dateString}`}</p>
                            <p>{`From: ${modTimeString} - ${modEndTimeString}`}</p>
                            <p>{`Where: ${event.data.address} ${event.data.city}, ${event.data.state}`}</p>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}