import React, { useState } from 'react'
import {addDoc, collection} from 'firebase/firestore'
import {db} from '../lib/init-firebase'

export default function AddEvent() {
    const [eventTitle, setEventTitle] = useState('')
    function handleSubmit(e){
        e.preventDefault()
        if(eventTitle === ''){
            return
        }
        const eventsCollRef = collection(db, 'events')
        addDoc(eventsCollRef, {
            eventTitle: eventTitle,
            date: Date.now(),
            endDate: Date.now()+10000,
            address: "600 E Mountain View Ave",
            city: "Longmont",
            state: "CO",
            clubId: "RzFham2ZxQI5dnvXZrPt",
            clubName: "Skyline Booster Club",
            coords: {
                latitude: 40.1820465,
                longitude: -105.0813249
            },
            creator:"TNTZ54FBAtY9DrPphjOW1q1z5Tt2",
            schoolId: "ysktp4xoWjGqYkKNIAU8"
        })
            .then(response =>{
                console.log(response)
            })
            .catch(error=>{
                console.log(error.message)
            })
        alert(eventTitle)
    }
    return (
        <>
            <h2>Add Event</h2>
            <form onSubmit={handleSubmit}>
                <label htmlFor='eventTitle'>Event</label>
                <input id='eventTitle' type='text' value={eventTitle} onChange={ e=>{
                    setEventTitle(e.target.value)
                }}/>
                <button type='submit'>Add Event</button>
            </form>
        </>
    )
}